import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import ProjectService from '../../../services/projects.service';

const projectService = new ProjectService();

const CloneProject = (props) => {

    const [project, setProject] = useState({
        title: "",
        description: "",
        budget: "",
        category: [],
    });

    const { title, description, budget, category } = project;

    const [initialTitle, setInitialTitle] = useState({title: ""})

    useEffect(() => {
        setInitialTitle(props.stats)
        setProject(props.stats)

    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        
        projectService
            .addProject(project)
            .then(res => {
                console.log(res)
                props.closeModal()
                props.allProjects()
            })
            .catch(err => err ? window.alert('Titulo de proyecto ya en uso, pruebe otro.') : null)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.currentTarget
        setProject({ ...project, [name]: value })
    }


    const dropDownProjects = project.category.map(elm => <li key={elm} className="userProjects"><label><input onClick={() => project.category.includes(elm) ? (project.category.indexOf(elm) > -1 ? project.category.splice(project.category.indexOf(elm), 1) : null) : project.category.push(elm) } type='checkbox' key={elm + 1} value={elm} defaultChecked={project.category.includes(elm)} />{elm}</label></li>)



    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' controlId='title'>
                <Form.Label>Titulo</Form.Label>
                <Form.Control onChange={handleInputChange} value={title} name="title" type="text" />
            </Form.Group>

            <Form.Group className='mb-3' controlId='description'>
                <Form.Label>Cliente</Form.Label>
                <Form.Control onChange={handleInputChange} value={description} name="description" type="text" />
            </Form.Group>

            <Form.Group className='mb-3' controlId='budget'>
                <Form.Label>Presupuesto</Form.Label>
                <Form.Control onChange={handleInputChange} value={budget} name="budget" type="number" />
            </Form.Group>

            <Form.Group className='mb-3' controlId='category'>
                    <Dropdown className="d-inline" autoClose="outside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside" className='btn-secondary  w-100'>
                        Categorias
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {dropDownProjects}
                        </Dropdown.Menu>
                    </Dropdown>
            </Form.Group>

            <Button variant='primary' type='submit'>
                Crear
            </Button>
        </Form>
    );
};

export default CloneProject;