import React, { useState, useEffect } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  startOfMonth,
  lastDayOfMonth,
} from "date-fns";

import './Calendar.css'

import { FormControl } from "react-bootstrap";
import ScheduleService from "../../../services/schedule.service";
import ProjectScheduleCard from "./ProjectScheduleCard";

const scheduleService = new ScheduleService()




const ProjectSchedules = ( props ) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    
    // console.log("selected day", selectedDate);
    return (
      <div className="header row flex-middle  ">
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> */}
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfMonth(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
        
      );
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = () => {
    const startDate = startOfMonth(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfMonth(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 31; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`inProject-tarjeta tarjeta col cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "cccc dd MMMM yyyy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="number">{formattedDate}</span>
            <div className="longer-user-div shorter-user-div">

              {/* MOSTRAR HORARIOS USUARIOS EN EL MISMO PROYECTO */}
              
            {users && users.map(elm => {
              let workArr = []
              let projectScheduleArr = []

              schedules.map(elm => elm.project._id == props.projectId._id ? projectScheduleArr.push(elm) : null)

              let worked = projectScheduleArr.map(elm2 => elm2.date == format(cloneDay, "cccc dd MMMM yyyy") && elm2.user._id == elm._id && elm2.date ? workArr.push(elm._id) : null  )


              return  workArr.includes(elm._id) ? <div key={elm._id}><ProjectScheduleCard projectId={props.projectId} key={elm._id} cloneDay={cloneDay} loggedUser={props.loggedUser} {...elm} /> </div>  : null

            })}
            </div>

            
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row row-tarjetas short-row-tarjetas" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <div className="header row flex-middle footer-fix ">
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeWeekHandle("prev")}>
            prev week
          </div> */}
          <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div>
        </div>
        <div className="week" ></div>

        <div className="col col-end" onClick={() => changeMonthHandle("next")}>
          <div className="icon">next month</div>
        </div>
        
        {/* <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon">next week</div>
        </div> */}
      </div>
    );
  };



// MYSCHEDULE

const [schedules, setSchedules] = useState([]);

// todo

const allSchedules = () => {

    scheduleService
        .allSchedules()
        .then(res => {
            const schedules = res.data
            setSchedules(schedules)

          })
        .catch(err => console.log(err))
}


// mostrar todos los usuarios

const [users, setUsers] = useState([]);


useEffect(() => {
    allSchedules()
}, []);

useEffect(() => {
    setUsers(props.usersInProject)
}, [props.usersInProject]);



// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));
  
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            controlId="project"
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  


  return (
    <>



        <div className="calendar inProject-calendar">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            {renderFooter()}
        </div>
        
    
    </>
  );
};

export default ProjectSchedules;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
