import React, {useState, useEffect} from 'react';
import { Card, Button, Form, Dropdown, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './UserCard.css'

import UserService from '../../../services/user.service';
import ProjectService from '../../../services/projects.service';


const userService = new UserService();
const projectService = new ProjectService();

const UserCard = (props) => {

    const [profile, setProfile] = useState({
        _id: "",
        role: "",
        projects: [],
        name: "",
        lastname: "",
        cost: "",
        password: "",
        accountsUser: false
    });

    const [passwordCheck, setPasswordCheck]= useState({ newPassword: '' });




    const [rolActual, setRolActual] = useState({role: props.role})


    const [projects, setProjects] = useState([])

    // const optionList = projects.map(elm => ({value: elm, label: elm.title, name: "projects", key: elm._id, id: elm._id, checked: profile.projects.includes(elm._id)}))


    const dropDownProjects = projects.map(elm => <li key={elm._id} className="userProjects"><label><input onClick={() => profile.projects.includes(elm._id) ? (profile.projects.indexOf(elm._id) > -1 ? profile.projects.splice(profile.projects.indexOf(elm._id), 1) : null) : profile.projects.push(elm._id) }  type='checkbox' key={elm._id} value={elm._id} defaultChecked={profile.projects.includes(elm._id)} />{elm.title}</label></li>)



    const allProjects = () => {
    
        projectService
            .allProjects()
            .then(res => {
                const projects = res.data
                let filteredProjects = projects.filter((project) => project.status === 'ABIERTO' )
          
                setProjects(filteredProjects)
            })
            .catch(err => console.log(err))
    }


    

    const handleSubmit = (e) => {
        e.preventDefault();
    
        let profileToUpdate = {...profile}; // Copiamos el objeto profile a un nuevo objeto
    
        if (passwordCheck.newPassword.length > 0) {
            profileToUpdate.password = passwordCheck.newPassword;

            closeModalEditPassword()
        }
        
        userService
            .userEdit(profileToUpdate)
            .then(response => {
                setProfile(response.data)
                setRolActual({role: profile.role})
                props.getAllUsers()
            })
            .catch(err => console.log(err))
    }
    

    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedValue = e.target.type === "checkbox" ? e.target.checked : value; // <-- manejar cambios de checkbox
        setProfile({ ...profile, [name]: updatedValue });
    }
    



    useEffect(() => {
        setProfile(prevProfile => ({
            ...props,
            password: prevProfile.password // Esto garantiza que la contraseña actual no se sobreescriba
        }));
        allProjects();
    }, []);
    

    // Quitar proyectos del array de proyectos del usuario
    // profile.projects.indexOf(elm.value) > -1 ? profile.projects.splice(profile.projects.indexOf(elm.value), 1)



    const [modal, setModal]= useState({ showModal: false });
    const { showModal } = modal

    const closeModal = () => {
        setModal({
            showModal: false
        })
    }

    const openModal = () => {
        setModal({
            showModal: true
        })
    }



    const [modalEdit, setModalEdit]= useState({ showModalEdit: false });
    const { showModalEdit } = modalEdit

    const closeModalEdit = () => {
        setModalEdit({
            showModalEdit: false
        })
    }

    const openModalEdit = () => {
        setModalEdit({
            showModalEdit: true
        })
    }

    const [modalEditPassword, setModalEditPassword]= useState({ showModalEditPassword: false });
    const { showModalEditPassword } = modalEditPassword

    const closeModalEditPassword = () => {
        setModalEditPassword({
            showModalEditPassword: false
        })
    }

    const openModalEditPassword = () => {
        setModalEditPassword({
            showModalEditPassword: true
        })
    }



    const newPasswordChecker = (e) => {
        const { name, value } = e.target
        
        setPasswordCheck({ ...passwordCheck, [name]: value})
    }


    return (
        <>
            <Card className="user-card" style={{ width: '18rem' }}>
                <Card.Body>
                    
                    <Card.Title onClick={openModal} style={{'color': props.loggedUser._id === props._id ? '#C9E86E' : 'white', width: 'fit-content', cursor: 'pointer'}}> {props.name} {props.lastname}</Card.Title>
                    <Card.Text className='actual-role'>{rolActual.role}</Card.Text>

                    <div className='user-card-button2'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="row">
                                <div className='col'>
                                    
                                    <Form.Select name="role" type="select" onChange={handleInputChange}>
                                        {profile.role === 'ADMIN' ? <option value={'ADMIN'} selected="selected" >ADMIN</option> : <option value={'ADMIN'}>ADMIN</option> }
                                        {profile.role === 'USER' ? <option value={'USER'} selected="selected" >USER</option> : <option value={'USER'}>USER</option> }
                                        {profile.role === 'USER_EXTERNAL' ? <option value={'USER_EXTERNAL'} selected="selected">USER_EXTERNAL</option> : <option value={'USER_EXTERNAL'}>USER_EXTERNAL</option> }
                                        {profile.role === 'INACTIVE' ? <option value={'INACTIVE'} selected="selected">INACTIVE</option> : <option value={'INACTIVE'}>INACTIVE</option> }
                                    </Form.Select>

                                </div>
                                <div style={{display: profile.role !== 'USER_EXTERNAL' ? 'none' : 'initial' }} className='col'>
                                <Dropdown className="d-inline" autoClose="outside">
                                    <Dropdown.Toggle id="dropdown-autoclose-outside" className='btn-secondary  w-100'>
                                    Proyectos
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {dropDownProjects}
                                    </Dropdown.Menu>
                                </Dropdown>
                                </div>

                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Check 
                                    type="checkbox" 
                                    label="Es de cuentas" 
                                    checked={profile.accountsUser} 
                                    onChange={handleInputChange} 
                                    name="accountsUser" 
                                />
                            </Form.Group>
                            <div className='padd-t-1'>
                            <Button type='submit' className='w-100' variant="primary">Aplicar</Button>
                            </div>

                        </Form>

                    </div>

                </Card.Body>
            </Card>


            <Modal show={modal.showModal} backdrop='static' onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        {props.name} {props.lastname}
                        <br />
                        {props.email}
                        <br />
                        Rol: {rolActual.role}
                        <br />
                        Coste: {props.cost}
                        <br />
                    </p>
                    <Button onClick={openModalEdit}>Editar</Button> 
                </Modal.Body>
            </Modal>


            <Modal show={modalEdit.showModalEdit} backdrop='static' onHide={closeModalEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar</Modal.Title>
                </Modal.Header>

                <Modal.Body>


                    <Form onSubmit={handleSubmit}>
                    <Form.Group className='mb-3' controlId='name'>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control onChange={handleInputChange} value={profile.name} name="name" type="text" />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='lastname'>
                        <Form.Control onChange={handleInputChange} value={profile.lastname} name="lastname" type="text" />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control onChange={handleInputChange} value={profile.email} name="email" type="email" />
                    </Form.Group>
                        <Form.Group className="row">
                        <Form.Label>Rol</Form.Label>
                                <Form.Select name="role" type="select" onChange={handleInputChange}>
                                    {profile.role === 'ADMIN' ? <option value={'ADMIN'} selected="selected" >ADMIN</option> : <option value={'ADMIN'}>ADMIN</option> }
                                    {profile.role === 'USER' ? <option value={'USER'} selected="selected" >USER</option> : <option value={'USER'}>USER</option> }
                                    {profile.role === 'USER_EXTERNAL' ? <option value={'USER_EXTERNAL'} selected="selected">USER_EXTERNAL</option> : <option value={'USER_EXTERNAL'}>USER_EXTERNAL</option> }
                                    {profile.role === 'INACTIVE' ? <option value={'INACTIVE'} selected="selected">INACTIVE</option> : <option value={'INACTIVE'}>INACTIVE</option> }
                                </Form.Select>

                            <div style={{display: profile.role !== 'USER_EXTERNAL' ? 'none' : 'initial' }} className='col'>
                            <Dropdown className="d-inline" autoClose="outside">
                                <Dropdown.Toggle id="dropdown-autoclose-outside" className='btn-secondary  w-100'>
                                Proyectos
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {dropDownProjects}
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </Form.Group>

                        <div className='padd-t-1'>
                            <Button onClick={openModalEditPassword} className='w-100' variant="primary">Cambiar contraseña</Button>
                        </div>
                        <div className='padd-t-1'>
                            <Button type='submit' className='w-100' variant="primary">Aplicar Cambios</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>



            <Modal show={modalEditPassword.showModalEditPassword} backdrop='static' onHide={closeModalEditPassword}>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar contraseña</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                    {/* <Form.Group className='mb-3' controlId='password'>
                        <Form.Label>Nueva contraseña</Form.Label>
                        <Form.Control onChange={handleInputChange} value={profile.password} name="password" type="password" />
                    </Form.Group> */}
                    <Form.Group className='mb-3' controlId='newPassword'>
                        <Form.Label>Confirma nueva contraseña</Form.Label>
                        <Form.Control onChange={newPasswordChecker} value={passwordCheck.newPassword} name="newPassword" type="password" />
                    </Form.Group>
                        <div className='padd-t-1'>
                            <Button type='submit' className='w-100' variant="primary" disabled={passwordCheck.newPassword.length < 1} >Aplicar nueva contraseña</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default UserCard