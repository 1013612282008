import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScheduleService from '../../../services/schedule.service';

const scheduleService = new ScheduleService()



const Schedule = ({ _id, date, hours, user, project, handleDelete, category, comment }) => {
    
    
    

    
    const scheduleDelete = () => {
        scheduleService
            .scheduleDelete(_id)
            .then(response => (null))
            .then(asd => handleDelete())
            .catch(err => console.log(err))
    }
    


    return (
        <Card>
            <Card.Body>
                <div className="dia">
                <div className="row">
                    <div className="col">
                        <p className='titleproj'>{project.title}</p>
                    </div>
                    <div className="col-md-auto">
                        <p className='hours'>{hours} h.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className='cat'>{category}</p>
                        <p className='comment2'>{comment}</p>
                    </div>
                    <div className="col-md-auto">
                    <Button className='btntrash' onClick={scheduleDelete}>
                    <svg className='trashicon' width="700pt" height="700pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
                        <path d="m225.55 93.332v-31.109c0-34.363 27.586-62.223 62.164-62.223h124.56c34.332 0 62.164 27.617 62.164 62.223v31.109h77.832c8.5586 0 15.5 7.2188 15.5 15.559 0 8.5898-6.8398 15.555-15.5 15.555h-15.609v368.69c0 36.93-23.922 66.863-53.492 66.863h-266.35c-29.543 0-53.492-29.902-53.492-66.863v-368.69h-15.609c-8.5586 0-15.5-7.2148-15.5-15.555 0-8.5938 6.8398-15.559 15.5-15.559zm-31.109 401.66c0 18.742 11.664 33.902 26.078 33.902h258.95c14.418 0 26.078-15.164 26.078-33.902v-370.54h-311.11zm62.223-401.66h186.66v-31.109c0-17.328-13.805-31.113-31.051-31.113h-124.56c-17.285 0-31.051 13.82-31.051 31.113zm31.109 140.11c0-8.6523 7.2148-15.664 15.555-15.664 8.5938 0 15.559 7.2461 15.559 15.664v186.45c0 8.6484-7.2188 15.66-15.559 15.66-8.5898 0-15.555-7.2461-15.555-15.66zm93.332 0c0-8.6523 7.2188-15.664 15.559-15.664 8.5898 0 15.555 7.2461 15.555 15.664v186.45c0 8.6484-7.2148 15.66-15.555 15.66-8.5938 0-15.559-7.2461-15.559-15.66z"/>
                    </svg>
                </Button>
                    </div>
                </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Schedule;