import React, {useEffect, useState} from 'react';
import { Card, Button, ProgressBar, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CloneProject from './CloneProject';

const Project = ({ _id, title, description, status, user, budget, category, allProjects, schedules }) => {
    
    // No necesitas buscar schedules aquí, simplemente los usas directamente.
    const id = _id;

    const filteredSchedules = schedules.filter(schedule => schedule.project._id === id);
    const filterHours = filteredSchedules.map(elm => elm.hours);
    const allHours = filterHours.reduce((a, b) => a + b, 0);

    let totalCostArr = []
    let finalCost = totalCostArr.reduce((a, b) => a + b, 0)

    const totalCost = user && user.map(elm => {

        let arrHoras = []
        
        let check = filteredSchedules.map(elm2 => elm2.user._id.includes(elm._id) ? arrHoras.push(elm2.hours) : null )

        let horas = arrHoras.reduce((a, b) => a + b, 0)

        let costeHora = elm.cost
        let costeTotal = elm.cost * horas

        
        totalCostArr.push(costeTotal)

        // console.log(totalCostArr.reduce((a, b) => a + b, 0))
        
        return null
    })


    const barProgress = (totalCostArr.reduce((a, b) => a + b, 0) * 100) / budget

    // IMPLEMENTACION DE LA BARRA DE PRESUPUESTO




    // CLONACION
    const [modal, setModal]= useState({ showModal: false });
    const { showModal } = modal

    const closeModal = () => {
        setModal({
            showModal: false
        })
    }

    const openModal = () => {
        setModal({
            showModal: true
        })
    }

    // CLONACION
    

    
    return (

        <>
            <Card>
                <Card.Body>
                    <div className="row verticalalign project">
                        <div className="col">
                        <span className='status v2'>{status}</span>
                            <Card.Title> {title} </Card.Title>                        
                        </div>
                        <div className="col ">
                            <div className='project-bar'>
                                <ProgressBar now={barProgress} className={`${barProgress.toFixed(2) < 100 ? 'in-progress' : 'complete'}`} label={`${barProgress.toFixed(2)}%`} />

                            </div>  
                        </div>
                        <div className="col-md-auto">
                            <div className="row flex-row-reverse">
                                <div className="col-md-auto">
                                <Link to={`/project/${_id}`}>
                                    <Button>Detalles</Button>
                                </Link>
                                <Button onClick={openModal} className='cloneBtn'>Clonar</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>


            <Modal show={modal.showModal} backdrop='static' onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Clonar proyecto</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <CloneProject allProjects={allProjects} stats={{ title, description, status, user, budget, category }} closeModal={closeModal} />
            </Modal.Body>
            </Modal>
        </>
    )
}

export default Project