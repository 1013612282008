import el from 'date-fns/esm/locale/el/index.js';
import React, { useEffect, useState } from 'react';
import { Container, Row, Button, Col, Modal, ProgressBar, Dropdown } from "react-bootstrap";
import ProjectService from '../../../services/projects.service';
import ScheduleService from '../../../services/schedule.service';
import EditProjectForm from './EditProjectForm';
import AddCategory from './AddCategory';
import { useParams } from 'react-router-dom'

import ProjectSchedules from '../Schedule/ProjectSchedules';

const projectService = new ProjectService()
const scheduleService = new ScheduleService()

const ProjectDetails = (props) => {

    const [schedules, setSchedules] = useState([]);
    const [project, setProject] = useState({
        _id: "",
        title: "",
        description: "",
        status: "",
        user: "",
        budget: "",
        category: []
    })

    const { id } = props.match.params


    const { title, description, status, user, budget, category } = project

    const projectDetails = () => {

        projectService
            .projectDetails(id)
            .then(res => {
                const { _id, title, description, status, user, budget, category } = res.data

                setProject({ _id, title, description, status, user, budget, category })
            })
            .catch(err => console.log(err))
    }

    const allSchedules = () => {
        scheduleService
            .allSchedules()
            .then(res => {
                const schedules = res.data
                setSchedules(schedules)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        projectDetails();
        allSchedules();
    }, []);




    
    const filteredSchedules = []
    schedules.filter(elm => elm.project._id === id ? filteredSchedules.push(elm) : null)
    const filterHours = filteredSchedules.map(elm => elm.hours)
    const allHours = filterHours.reduce((a, b) => a + b, 0)

    


    const userList = user && user.map(elm => {

        let arrHoras = []
        
        let check = filteredSchedules.map(elm2 => elm2.user._id.includes(elm._id) ? arrHoras.push(elm2.hours) : null )

        let horas = arrHoras.reduce((a, b) => a + b, 0)

        let costeHora = elm.cost
        let costeTotal = elm.cost * horas
        

        
        return <ul key={elm._id}> {horas > 0 ? <li key={elm._id}>{elm.name} {elm.lastname} | {horas.toFixed(2).replace(/\.00$/, '')}h. Coste: {costeHora}€ /h Total: {costeTotal.toFixed(2).replace(/\.00$/, '')}€ </li> : null} </ul>
    
    
    })

    let totalCostArr = []
    let finalCost = totalCostArr.reduce((a, b) => a + b, 0)

    const totalCost = user && user.map(elm => {

        let arrHoras = []
        
        let check = filteredSchedules.map(elm2 => elm2.user._id.includes(elm._id) ? arrHoras.push(elm2.hours) : null )

        let horas = arrHoras.reduce((a, b) => a + b, 0)

        let costeHora = elm.cost
        let costeTotal = elm.cost * horas

        
        totalCostArr.push(costeTotal)

        // console.log(totalCostArr.reduce((a, b) => a + b, 0))
        
        return null
    
    
    })



    const [modal, setModal]= useState({ showModal: false });
    const { showModal } = modal

    const closeModal = () => {
        setModal({
            showModal: false
        })
    }

    const openModal = () => {
        setModal({
            showModal: true
        })
    }



    
    const [modalCat, setModalCat]= useState({ showModalCat: false });
    const { showModalCat } = modalCat

    const closeModalCat = () => {
        setModalCat({
            showModalCat: false
        })
    }

    const openModalCat = () => {
        setModalCat({
            showModalCat: true
        })
    }



    const handleEdit = () => { 

        allSchedules()
        projectDetails()
    }

    
    const barProgress = (totalCostArr.reduce((a, b) => a + b, 0) * 100) / budget
    

    
    const categorias = category.map(elm => {
        
        const hoursArr = []
        filteredSchedules.map(elm2 => elm2.category === elm ? hoursArr.push(elm2.hours) : null )
        
        let categoryHours = hoursArr.reduce((a, b) => a + b, 0)
        
        return <ul key={elm}><li key={elm}>{elm}: {categoryHours} h.</li></ul>
    })



    // console.log(user)

    return (

        <div>
            {props.loggedUser?.role === 'ADMIN' ? 
                    <Container>
                    </Container>
            
            : null}

                    <Container>
                    </Container>
            

            <Container>
                <div className='listproduct'>

                <Row>
                    <div className="row ">
                        <div className="col">
                                <span className='status'>{status}</span>
                                <h1>{title}</h1>
                            <p>{description}</p>
                        </div>
                        <div className="col">
                            <div className="row flex-row-reverse">
                                <div className="col-md-auto">
                                </div>
                                <div className="col-md-auto">
                                <Button onClick={openModal}>Editar</Button> 
                        <Modal show={showModal} backdrop="static" onHide={closeModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Project</Modal.Title>
                            </Modal.Header>
        
                            <Modal.Body>
                                <EditProjectForm project={project} closeModal={closeModal} setProject={setProject} handleEdit={handleEdit} />
                            </Modal.Body>
                        </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <ProgressBar now={barProgress} className={`${barProgress.toFixed(2) < 100 ? 'in-progress' : 'complete'}`} label={`${barProgress.toFixed(2)}%`} />
                    <br />
                    <hr />
                    <article className='padd-b-100'>
                    <hr />
                        <div className="row">
                            <div className="col">
                                
                            <h3>Categorias:</h3>
                                {categorias}
                                <Button onClick={openModalCat}>Añadir categoria</Button> 
                                    <Modal show={showModalCat} backdrop="static" onHide={closeModalCat}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Añadir categoria</Modal.Title>
                                        </Modal.Header>
                    
                                        <Modal.Body>
                                            <AddCategory project={project} closeModalCat={closeModalCat} setProject={setProject} handleEdit={handleEdit} />
                                        </Modal.Body>
                                    </Modal>
                            </div>
                            <div className="col">
                            <h3>Presupuesto: {budget}€</h3>
                        <h3>Coste total: {totalCostArr.reduce((a, b) => a + b, 0).toFixed(2).replace(/\.00$/, '')}€</h3>
                        <h3>Horas Totales del proyecto: {allHours.toFixed(2).replace(/\.00$/, '')} h.</h3>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                
                                <Dropdown className="d-inline" autoClose="outside ">
                                <Dropdown.Toggle id="dropdown-autoclose-outside" className='btn-secondary w-100 '>
                                Usuarios
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="w-100">
                                    {userList}
                                </Dropdown.Menu>
                            </Dropdown>
                                {/* {userList} */}
                            </div>
                        </div>

                    </article>
                </Row>
                </div>

            
            </Container>

            <ProjectSchedules usersInProject={user} projectId={project} />


        </div>




    );
};

export default ProjectDetails;


