import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button, Container } from 'react-bootstrap';
import Project from './Project'
import AddProject from './AddProject';
import ScheduleService from '../../../services/schedule.service';

const scheduleService = new ScheduleService()

const ProjectList = ({projects, loggedUser, props, allProjects, projectsByStatus}) => {


    const [modal, setModal] = useState({ showModal: false });


    const openModal = () => {
        setModal({
            showModal: true
        })
    }

    const closeModal = () => {
        setModal({
            showModal: false
        })
    }


    const [todo, setTodo] = useState(false);

    const mostrarTodo = () => {
        allProjects();
    }

    const [abierto, setAbierto] = useState(true);

    const mostrarAbierto = () => {
        projectsByStatus('ABIERTO');
    }

    const [cerrado, setCerrado] = useState(false);

    const mostrarCerrado = () => {
        projectsByStatus('CERRADO');
    }


    const [schedules, setSchedules] = useState([]);

    const allSchedules = () => {
        scheduleService
            .allSchedules()
            .then(res => {
                const schedules = res.data;
                setSchedules(schedules);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        allSchedules();
    }, []);



    return (
        <div>
            {loggedUser?.role === 'ADMIN' ? 
                <div className="row padd-b-20">
                    <Container>
                        <Button onClick={openModal}>Añadir</Button>&nbsp;&nbsp;
                        <Button onClick={mostrarTodo}>Todos</Button>&nbsp;&nbsp;
                        <Button onClick={mostrarAbierto}>Abiertos</Button>&nbsp;&nbsp;
                        <Button onClick={mostrarCerrado}>Cerrados</Button>
                        <Modal show={modal.showModal} backdrop='static' onHide={closeModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Crear proyecto</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <AddProject allProjects={mostrarAbierto} closeModal={closeModal}  />
                            </Modal.Body>
                        </Modal>
                    </Container>
                </div>
            : null}

            <Row >
                {projects.map(elm => <div key={elm._id}><Project allProjects={mostrarAbierto} schedules={schedules} {...elm} loggedUser={loggedUser} /></div>)}

            </Row>
        </div>
    );
};

export default ProjectList;