import React, { useState, useEffect, useRef } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks
} from "date-fns";

import './Calendar.css'

import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { Container, Row, Col, Modal, Button, Form, Dropdown, FormControl, Spinner } from "react-bootstrap";
import Schedule from "./Schedule";
import ScheduleService from "../../../services/schedule.service";
import AddSchedule from "./AddSchedule";
import ProjectService from '../../../services/projects.service';

const scheduleService = new ScheduleService()
const projectService = new ProjectService();




const Calendar = ( props ) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    
    // console.log("selected day", selectedDate);
    return (
      <div className="header row flex-middle  ">
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> */}
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
        
      );
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`tarjeta col cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "cccc dd MMMM yyyy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="number">{formattedDate}</span>
            <div>
            {schedules.map(elm => {
              return elm.date == format(cloneDay, "cccc dd MMMM yyyy") ? <div key={elm._id}><Schedule {...elm} loggedUser={props.loggedUser} handleDelete={handleDelete} /></div> : null
              })}
            </div>
            <Button className="tarjeta-cta" onClick={openModal}>Añadir</Button>

            
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row row-tarjetas" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <div className="header row flex-middle footer-fix ">
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            prev week
          </div>
        </div>
        <div className="week" >{currentWeek}</div>
        <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon">next week</div>
        </div>
      </div>
    );
  };



// MYSCHEDULE

const [schedules, setSchedules] = useState([]);


const allSchedules = () => {
  scheduleService
      .mySchedules(props.loggedUser._id)
      .then(res => {
          setSchedules(res.data)
      })
      .catch(err => console.log(err))
}



const [modal, setModal] = useState({ showModal: false });

const closeModal = () => {
    setModal({
        showModal: false
    })
}

const openModal = () => {

    setModal({
        showModal: true
    })
}




// ADDPROJECT

const [isSubmitting, setIsSubmitting] = useState(false);


const [projectUser, setProjectUser] = useState({

  user: []

})
const [projects, setProjects] = useState([])
const [schedule, setSchedule] = useState({
    date: "",
    project: "",
    user: props.loggedUser._id ,
    hours: '',
    category: "",
    comment: ""
});

const { date, hours, project, category, comment } = schedule;


const allProjects = () => {
    
    projectService
        .allProjects()
        .then(res => {
            const projects = res.data
            let filteredProjects = projects.filter((project) => (props.loggedUser.role != 'USER_EXTERNAL' && props.loggedUser.role != 'INACTIVE') && project.status === 'ABIERTO' ? project : (props.loggedUser.role === 'USER_EXTERNAL' && project.status === 'ABIERTO' && props.loggedUser.projects.includes(project._id) ? project : null ))
      
            setProjects(filteredProjects)
        })
        .catch(err => console.log(err))
}


useEffect(() => {
    allSchedules()
    allProjects();

}, []);



const projectId = schedule.project


const isSubmittingRef = useRef(false);

const handleSubmit = (e) => {
    e.preventDefault();

        // Si ya se está sometiendo, salir tempranamente para prevenir el proceso adicional.
        if(isSubmittingRef.current) return;

        // Marcar como sometiéndose
        isSubmittingRef.current = true;
        setIsSubmitting(true);

    scheduleService
        .addSchedule(schedule)
        .then(res => {
            closeModal()
            allSchedules()
            isSubmittingRef.current = false;
            setIsSubmitting(false); 
        })
        .catch(err => {
          console.log(err)
          isSubmittingRef.current = false;
          setIsSubmitting(false);
        });
        

      
    
    let proyecto = projects.filter((elm) => elm._id === project)
    let userInProject = proyecto[0].user.map(elm => {
     let arr = elm._id.includes(props.loggedUser._id) ? true : false
     return arr
    
    })

    // console.log(userInProject.length)

    if(userInProject.length === 0){
      
      allProjects();
    }

    if (!userInProject.includes(true)) {
      projectService
      .addUser(projectId)
      .then(response => {
          const user = response.data
          setProjectUser({...projectUser, user: user})
      })
      .catch(err => console.log(err));
      allProjects();
      allSchedules();
    } 
    





}

let proyecto = projects.filter((elm) => elm._id === project)


const handleInputChange = (e) => {
    const { name, value } = e.target



    // console.log(proyecto[0].user.includes(props.loggedUser._id))
    // console.log('Mi id es: ',props.loggedUser._id)
    // let userInProject = proyecto[0].user.includes(props.loggedUser._id)
    // console.log(e.target)
    // console.log(proyecto[0]?.category.map(elm => elm))

    setSchedule({ ...schedule, [name]: value, date: format(selectedDate, "cccc dd MMMM yyyy") })
}


const handleDelete = () => { 

  setSchedules([])
  allSchedules()
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));
  
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      // console.log()
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            controlId="project"
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  

    const options =  projects.map(elm => {return {value: elm._id, label: elm.title}})
    
  



  const handleProject = (e) => {

    const name = e.value

    // console.log(proyecto[0].user.includes(props.loggedUser._id))
    // console.log('Mi id es: ',props.loggedUser._id)
    // let userInProject = proyecto[0].user.includes(props.loggedUser._id)


    setSchedule({ ...schedule, project: name })
}

  return (
    <>
        <Modal show={modal.showModal} backdrop='static' onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Registrar Horario</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='mb-3' controlId='date'>
                        <Form.Control onChange={handleInputChange}  value={selectedDate} name="date" type="text" hidden/>
                    </Form.Group>


                    
                    <Select options={options} onChange={handleProject} />
                    <br />

                    {/* <Form.Group className="mb-3" controlId="project">
                        <Form.Label>Proyecto</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={handleInputChange} name="project" type="text">
                            <option value={null}>Seleccionar</option>
                            {projects.map(elm => (
                                elm.status === 'ABIERTO' ? <option key={elm._id} value={elm._id}>{elm.title}</option> : null
                                ))}
                        </Form.Select>
                    </Form.Group> */}

                    <Form.Group className="mb-3" controlId="category">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={handleInputChange} name="category" type="text">
                            <option value={null}>Seleccionar</option>
                            {proyecto[0]?.category.map(elm =>  (<option key={elm} value={elm}>{elm}</option>))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='hours'>
                        <Form.Label>Horas</Form.Label>
                        <Form.Control onChange={handleInputChange} value={hours} name="hours" type="number" maxLength="2" />
                    </Form.Group>

                    <Form.Group controlId="comment">
                      <Form.Label>Comentario</Form.Label>
                      <Form.Control className='mb-3' as="textarea" onChange={handleInputChange} value={comment} name="comment" maxLength="200"  />
                    </Form.Group>


                    <Button 
                        variant='primary' 
                        type='submit' 
                        style={isSubmitting ? { pointerEvents: 'none', opacity: 0.7 } : {}}
                    >
                        {isSubmitting ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {' '}Cargando...
                          </>
                        ) : 'Añadir'}
                    </Button>


                </Form>

            </Modal.Body>
        </Modal>





        <div className="calendar">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            {renderFooter()}
        </div>
        
    
    </>
  );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
