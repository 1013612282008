import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import ProjectService from '../../../services/projects.service';

const projectService = new ProjectService();

const EditProjectForm = (props) => {

    const [project, setProject] = useState({
        _id: "",
        title: "",
        description: "",
        status: "",
        budget: "",
        category: []

    });

    const { title, description, status, budget } = project;


    useEffect(() => {
        setProject(props.project)
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        projectService
            .projectEdit(project)
            .then(res => {
                const info = res.data
                props.setProject(info)
                props.closeModal()
            })
            .then(asd => props.handleEdit())
            .catch(err => console.log(err))
            
    }

    const handleInputChange = (e) => {
        const { name, value } = e.currentTarget

        // console.log(status === 'ABIERTO' ? true : false)

        setProject({ ...project, [name]: value })
    }

    const dropDownProjects = project.category.map(elm => <li key={elm} className="userProjects"><label><input onClick={() => project.category.includes(elm) ? (project.category.indexOf(elm) > -1 ? project.category.splice(project.category.indexOf(elm), 1) : null) : project.category.push(elm) } type='checkbox' key={elm + 1} value={elm} defaultChecked={project.category.includes(elm)} />{elm}</label></li>)


    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' controlId='title'>
                <Form.Label>Titulo</Form.Label>
                <Form.Control onChange={handleInputChange} value={title} name="title" type="text" />
            </Form.Group>

            <Form.Group className='mb-3' controlId='description'>
                <Form.Label>Cliente</Form.Label>
                <Form.Control onChange={handleInputChange} value={description} name="description" type="text" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="status">
                <Form.Label>Estado</Form.Label>
                 <Form.Select aria-label="Default select example" onChange={handleInputChange} name="status" type="text">
                    {status !== 'ABIERTO' ? <option  value='ABIERTO'>ABIERTO</option> : <option selected value='ABIERTO'>ABIERTO</option>}
                    {status !== 'EN_PROGRESO' ? <option  value='EN_PROGRESO'>EN PROGRESO</option> : <option selected value='EN_PROGRESO'>EN PROGRESO</option>}
                    {status !== 'CERRADO' ? <option value='CERRADO'>CERRADO</option> : <option selected value='CERRADO'>CERRADO</option>}
                </Form.Select>
            </Form.Group>

            <Form.Group className='mb-3' controlId='budget'>
                <Form.Label>Presupuesto</Form.Label>
                <Form.Control onChange={handleInputChange} value={budget} name="budget" type="number" />
            </Form.Group>

            <Form.Group className='mb-3' controlId='category'>
                    <Dropdown className="d-inline" autoClose="outside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside" className='btn-secondary  w-100'>
                        Categorias
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {dropDownProjects}
                        </Dropdown.Menu>
                    </Dropdown>
            </Form.Group>


            <Button variant='primary' type='submit'>
                Aplicar cambios
            </Button>
        </Form>
    );
};

export default EditProjectForm;