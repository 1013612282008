import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import ProjectService from '../../../services/projects.service';
import ProjectList from './ProjectList';
import SearchBar from '../../layout/SearchBar/SearchBar';

const projectService = new ProjectService();

const Projects = (props) => {

    const [projects, setProjects] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);

    const [project, setProject] = useState({
        title: "",
        description: ""
    })

    const allProjects = () => {
        projectService
            .allProjects()
            .then(res => {
                const projects = res.data
                setProjects(projects)
                setFilteredProjects(projects)
            })
            .catch(err => console.log(err))
    }

    const projectsByStatus = (status) => {
        projectService
            .projectsByStatus(status)
            .then(res => {
                const projects = res.data
                setProjects(projects)
                setFilteredProjects(projects)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        projectsByStatus('ABIERTO')
    }, []);

    const getSearch = (searchbarInfo) => {
        setSearch(searchbarInfo);
    };

    useEffect(() => {
        let filteredProjects = projects.filter((project) => project.title.toLowerCase().includes(search));
        setFilteredProjects(filteredProjects)
    }, [search]);


    return (
        <div>

            <Container >
                <div className="row verticalalign">
                    <div className="col">
                    <div className='titlespage'>
                    <h1>Proyectos</h1>
                    </div>
                    </div>
                    <div className="col">
                        <div className="row flex-row-reverse">
                            <div className="col-md-auto">
                            <SearchBar getSearch={getSearch} />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="padd-b-7">
                {/* <ProjectList allProjects={allProjects} projects={filteredProjects} loggedUser={props.loggedUser} {...props} /> */}
                <ProjectList allProjects={allProjects} projectsByStatus={projectsByStatus} projects={filteredProjects} loggedUser={props.loggedUser} {...props} />

                </div>
            </Container>
        </div>
    );
};

export default Projects;