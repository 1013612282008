import axios from 'axios'

class UserService {
    constructor() {
        this.app = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/user`,
            withCredentials: true
        })
    }
    getAllUsers = () => this.app.get("/all")
    getUserDetails = (id) => this.app.get(`/profile/${id}`)
    getMyProfile = (id) => this.app.get(`/my-profile`)
    userEdit = (profile) => this.app.put(`/edit/${profile._id}`, profile)
    userDelete = (id) => this.app.delete(`/delete/${id}`)
    addProject = (id) => this.app.put(`/projects/add/${id}`)
    removeProject = (id) => this.app.put(`/projects/remove/${id}`)
    checkSession = () => this.app.get('/checkSessionExpiration')
    currentProject = (projectData) => this.app.put('/current-project', projectData)
}

export default UserService