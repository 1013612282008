import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ProjectService from '../../../services/projects.service';
import UserService from '../../../services/user.service';
import Temporizador from './Temporizador';

const projectService = new ProjectService();
const userService = new UserService();

const UserProject = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [endTimer, setEndTimer] = useState(null);


  useEffect(() => {
    // Obtener todos los proyectos con estado ABIERTO
    projectService
      .projectsByStatus('ABIERTO')
      .then(res => {
        const projectsData = res.data.map(project => ({ value: project._id, label: project.title }));
        setProjects(projectsData);
      })
      .catch(err => console.log(err));

    // Obtener el proyecto actual del usuario
    userService.getMyProfile()
      .then(response => {
        const currentProject = response.data.currentProject;
        setEndTimer(response.data.timerEnd); // Asume que endTimer viene en la respuesta

        console.log(response)
    
        if (currentProject) {
          setSelectedProject({ value: currentProject._id, label: currentProject.title });
        }
      })
      .catch(error => {
        console.error('Error al obtener el perfil del usuario:', error);
      });

  }, []);

  const handleChange = selectedOption => {
    setSelectedProject(selectedOption);
  };
  
  const asignProject = (timerDuration) => {
    if (selectedProject) {
      userService.currentProject({ projectId: selectedProject.value, timerDuration: timerDuration })
        .then(response => {
          console.log('Proyecto actualizado:', response.data);
        })
        .catch(error => {
          console.error('Error al actualizar el proyecto:', error);
        });
    } else {
      // Si selectedProject es null, significa que no hay un proyecto seleccionado.
      userService.currentProject({ projectId: null, timerDuration: 0 }) // Sin proyecto y sin duración
        .then(response => {
          console.log('Proyecto despejado:', response.data);
        })
        .catch(error => {
          console.error('Error al despejar el proyecto:', error);
        });
    }
  };
  

  const clearCurrentProject = () => {
    userService.currentProject({ projectId: null })
      .then(response => {
        console.log('Proyecto despejado:', response.data);
        setSelectedProject(null);
      })
      .catch(error => {
        console.error('Error al despejar el proyecto:', error);
      });
  };
  
  

  return (
    <div className='d-flex align-items-center set_activity_module_project'>
      <Select
        value={selectedProject}
        onChange={handleChange}
        options={projects}
        isClearable
        isSearchable
        placeholder="Seleccionar..."
        className='input_proj'
      />
      <Temporizador asignProject={asignProject} clearCurrentProject={clearCurrentProject} selectedProject={selectedProject} endTimer={endTimer} />
    </div>

  );
};

export default UserProject;
