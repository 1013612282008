import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import ProjectService from '../../../services/projects.service';

const projectService = new ProjectService();

const AddCategory = (props) => {

    const [project, setProject] = useState({
        category: []

    });
    const [addCat, setAddCat] = useState({
        category: []
    })

    const { category } = project;


    useEffect(() => {
        setProject(props.project)
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        projectService
            .projectEdit(project)
            .then(res => {
                const info = res.data
                props.setProject(info)
                props.closeModalCat()
            })
            .then(asd => props.handleEdit())
            .catch(err => console.log(err))


            
    }

    const handleInputChange = (e) => {
        const { name, value } = e.currentTarget

        setAddCat({...addCat, [name]: value})

    }

    const handleAddCategory = () => {
        setProject({ ...project, category: category.concat(addCat.category) })
        


    }



    return (
        <Form onSubmit={handleSubmit}>

            <Form.Group className='mb-3' controlId='category'>
                <Form.Label>Categoria</Form.Label>
                <Form.Control onChange={handleInputChange} value={addCat.category} name="category" type="text" />
            </Form.Group>

            <Button onClick={handleAddCategory} variant='primary' type='submit'>
                Añadir
            </Button>
        </Form>
    );
};

export default AddCategory;