import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AuthService from '../../../services/auth.service'
import './Navigation.css'
import logomaart from  '../../../assets/images/Maart.png'

import UserProject from '../../pages/Projects/UserProject'

import Temporizador from '../../pages/Projects/Temporizador';



const authService = new AuthService()

const Navigation = ({ loggedUser, storeUser }) => {

    const logout = () => {
        authService.logout()
            .then(response => storeUser(null))
            .catch(err => console.log(err))
    }

    

    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <div className='title-logo'>
                    <Navbar.Brand className='title' as={Link} to='/'><img className='logo' src={logomaart}></img></Navbar.Brand>
                </div>

                {loggedUser && loggedUser.role !== 'USER_EXTERNAL' ?  
                
                <div className='d-flex align-items-center '>
                    <UserProject />
                </div>

                : null }

                <Nav className="nav">
                    
                    {loggedUser && loggedUser.role === 'ADMIN' ?  <Nav.Link className='link' as={Link} to="/actividad">Equipo</Nav.Link>: null }
                    {/* {loggedUser && loggedUser.role === 'ADMIN' ? <Nav.Link className='link' as={Link} to="/user-list">Usuarios</Nav.Link> : null} */}
                    {loggedUser && loggedUser.role === 'ADMIN' ? <Nav.Link className='link' as={Link} to="/projects">Proyectos</Nav.Link> : null}
                    {/* {loggedUser && loggedUser.role === 'ADMIN' ? <Nav.Link className='link' as={Link} to="/signup">Registro</Nav.Link> : null} */}
                    {/* {loggedUser && loggedUser.role === 'ADMIN' ? <Nav.Link className='link' as={Link} to="/allSchedules">Calendario</Nav.Link> : null} */}
                    {loggedUser && loggedUser.role === 'ADMIN' ?  <Nav.Link className='link' as={Link} to="/my-schedule">Añadir horas</Nav.Link>: null }
                    {loggedUser && loggedUser.role === 'ADMIN' ? <Nav.Link className='link' as={Link} to="/userList">Usuarios</Nav.Link> : null}

                    {loggedUser ?
                        <>
                            {/* {loggedUser && loggedUser.role === 'ADMIN' ? <Nav.Link className='link' as={Link} to="/my-profile">Profile</Nav.Link> : null } */}

                            <Nav.Link className='link' as={Link} to='/' onClick={logout}>Desconectar</Nav.Link>
                        </>
                        :
                        <>
                            
                            <Nav.Link className='link' as={Link} to="/login">Acceder</Nav.Link>
                            
                        </>
                    }
                </Nav>
            </Container>
        </Navbar>
    )
}

export default Navigation
