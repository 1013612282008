import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap'

import UserService from '../../../services/user.service';

const userService = new UserService();


const Temporizador = ({ asignProject, clearCurrentProject, selectedProject, endTimer }) => {
  const [horas, setHoras] = useState(0);
  const [minutos, setMinutos] = useState(0);
  const [segundosRestantes, setSegundosRestantes] = useState(0);
  const [activo, setActivo] = useState(false);


  useEffect(() => {
    if (endTimer) {
        const now = new Date().getTime();
        const endTime = new Date(endTimer).getTime();
        const remainingTimeInSeconds = (endTime - now) / 1000;

        if (remainingTimeInSeconds > 0) {
            setSegundosRestantes(remainingTimeInSeconds);
            setActivo(true); // Reinicia el temporizador si hay tiempo restante
        }
    }
}, [endTimer]);

useEffect(() => {
  let interval;

  if (activo) {
      interval = setInterval(() => {
          setSegundosRestantes(prev => {
              if (prev > 0) {
                  return prev - 1;
              } else {
                  clearInterval(interval);
                  setActivo(false);
                  return 0;
              }
          });
      }, 1000);
  } else {
      clearInterval(interval);
  }

  return () => clearInterval(interval); // Limpieza del efecto

}, [activo]);



  const iniciarTemporizador = () => {
      if (horas === 0 && minutos === 0) {
          return;
      }
      

      const timerDuration = horas * 60 + minutos;
      asignProject(timerDuration);
      
  
      setSegundosRestantes(horas * 3600 + minutos * 60);
      setActivo(true);
  };
  
  const cancelarTemporizador = () => {
      setActivo(false);
      clearCurrentProject();
  };

  const onTimerEnd = () => {
      userService.currentProject({ projectId: null })
          .then(response => {
              console.log('Proyecto despejado:', response.data);
          })
          .catch(error => {
              console.error('Error al despejar el proyecto:', error);
          });
  };
  
  
    return (
        <div className='h-100 set_activity_module_time'>
          <div >
            {activo ? (
              <div className="text-white ms-1 d-flex align-items-center ">
                <span className='timeut_span'>
                Tiempo restante:<br></br> {Math.floor(segundosRestantes / 3600)}h {Math.floor((segundosRestantes % 3600) / 60)}m {Math.round(segundosRestantes % 60)}s

                </span>
                <Button variant="danger" className="ms-2 " onClick={cancelarTemporizador}>Cancelar</Button>
              </div>
            ) : (
              
              <Form className='d-flex'>
                <Form.Group className='ms-1' controlId="formBasicSelect">
                  <Form.Control as="select" disabled={activo} value={horas} onChange={(e) => setHoras(Number(e.target.value))} >
                    {[...Array(11).keys()].map(h => (
                      <option key={h} value={h}>{h}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className='ms-1' controlId="formBasicSelect">
                  <Form.Control as="select" disabled={activo} value={minutos} onChange={(e) => setMinutos(Number(e.target.value))} >
                    {[0, 15, 30, 45].map(m => (
                      <option key={m} value={m}>{m}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button className='ms-1' variant="secondary" onClick={iniciarTemporizador} disabled={!selectedProject || (horas === 0 && minutos === 0)}>Iniciar</Button>


              </Form>
            )}
          </div>
        </div>
      );
}

export default Temporizador;
