import axios from 'axios';

class ProjectService {
    constructor() {
        this.app = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/projects`,
            withCredentials: true
        })
    }

    allProjects = () => this.app.get("/projects");
    projectDetails = (id) => this.app.get(`/project/${id}`);
    projectEdit = (project) => this.app.put(`/edit/${project._id}`, project);
    projectDelete = (id) => this.app.delete(`/delete/${id}`);
    addProject = (project) => this.app.post("/new", project);
    addUser = (id) => this.app.put(`/addUser/${id}`);
    projectsByStatus = (status) => this.app.get(`/projects/status/${status}`);

}

export default ProjectService