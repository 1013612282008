import { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { Navigation, Login, Signup, UserPage, UserProfile, ProjectDetails, Projects, Calendar, AllSchedules, UserList } from './components';
import AuthService from './services/auth.service'
import MyProfile from './components/pages/UserProfile/MyProfile';
import ProtectedRoute from './components/ProtectedRoute';
import UserService from './services/user.service';
import ActivityList from './components/pages/Projects/ActivityList';


// import './App.css';


const authService = new AuthService()
const userService = new UserService()

function App() {

  const [loggedUser, setLoggedUser] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(null)
  const [sessionExpiredShown, setSessionExpiredShown] = useState(false);

  const history = useHistory();

  const loadUser = () => {

    authService
    .isloggedin()
    .then(response => storeUser(response.data))
    .catch(err => storeUser(null))
  }

  const loadSession = () => {

    userService
    .checkSession()
    .then(res => {
      const sessionStatus = res.data.sessionStatus
      const session = sessionStatus.cookie.expires
      const timestamp = Date.parse(session);

      setSessionExpired(timestamp)
    })
  }

  useEffect(() => {
    loadUser()
    loadSession()
  }, []);

  const storeUser = (user) => {
    setLoggedUser(user)
  }


  const handleSessionExpired = () => {
    if (!sessionExpiredShown) {
      setSessionExpiredShown(true);
      history.push('/')
      window.alert('Sesión caducada, vuelve a hacer login.')
      window.location.reload()
    }
  };

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (sessionExpired && sessionExpired < Date.now()) {
        handleSessionExpired();
      }
    }, 1000 * 60 * 30);
    return () => clearInterval(intervalId);
  }, [sessionExpired, sessionExpiredShown, history]);
  


  return (
    <>
      <Navigation storeUser={storeUser} loggedUser={loggedUser} />
      <main>
        <Switch>
          {loggedUser ? <Route path="/" exact render={(props) => <Calendar {...props} loggedUser={loggedUser} storeUser={storeUser} />} /> : <Route path="/" exact render={(props) => <Login {...props} storeUser={storeUser} setSessionExpired={setSessionExpired} />} />  }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/user-list" render={(props) => <UserPage loggedUser={loggedUser} {...props} />} /> : null }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/profile/:id" render={(props) => <UserProfile {...props} loggedUser={loggedUser} />} /> : null}
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/my-profile" render={(props) => <MyProfile loggedUser={loggedUser} storeUser={storeUser} {...props} />} /> : null }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/project/:id" exact render={(props) => <ProjectDetails {...props} loggedUser={loggedUser} />} /> : null }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/projects" exact render={(props) => <Projects {...props} loggedUser={loggedUser} /> } /> : null }
          {loggedUser ? <Route path="/my-schedule" exact render={(props) => <Calendar {...props} loggedUser={loggedUser} storeUser={storeUser} />} /> : null }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/actividad" exact render={(props) => <ActivityList />} /> : null }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/signup" render={(props) => <Signup {...props} storeUser={storeUser} />} /> : null }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/allSchedules" render={(props) => <AllSchedules {...props} storeUser={storeUser} />} /> : null }
          {loggedUser && loggedUser.role === 'ADMIN' ? <Route path="/userList" render={(props) => <UserList loggedUser={loggedUser} {...props} storeUser={storeUser} />} /> : null }
          {loggedUser ?
            <Redirect to="/" />
            :
            <>
              <Route path="/signup" render={(props) => <Signup {...props} storeUser={storeUser} />} />
              <Route path="/login" render={(props) => <Login {...props} storeUser={storeUser} setSessionExpired={setSessionExpired} />} />
              <ProtectedRoute exact path="/logout" />
            </>
          }

        </Switch>
      </main>
    </>
  )

}

export default App;