import axios from 'axios';

class ScheduleService {
    constructor() {
        this.app = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/schedules`,
            withCredentials: true
        })
    }

    allSchedules = () => this.app.get("/all");
    scheduleDetails = (id) => this.app.get(`/schedule/${id}`);
    scheduleEdit = (schedule) => this.app.put(`/edit/${schedule._id}`, schedule);
    scheduleDelete = (_id) => this.app.delete(`/delete/${_id}`);
    addSchedule = (schedule) => this.app.post("/new", schedule);
    mySchedule = () => this.app.get('/my-schedule');
    mySchedules = (userId) => this.app.get(`/my-schedules/${userId}`);

}

export default ScheduleService