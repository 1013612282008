import React, {useState, useEffect} from 'react';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScheduleService from '../../../services/schedule.service';

import { format } from "date-fns";

const scheduleService = new ScheduleService()



const ProjectScheduleCard = ({ _id, cloneDay, name, lastname, cost, comment, projectId }) => {
    
const [modal, setModal] = useState({ showModal: false });

const closeModal = () => {
    setModal({
        showModal: false
    })
}

const openModal = () => {
    setModal({
        showModal: true
    })
}

const [schedules, setSchedules] = useState([])

const allSchedules = () => {

    scheduleService
        .allSchedules()
        .then(res => {
            const schedules = res.data
            setSchedules(schedules)

          })
        .catch(err => console.log(err))
}

useEffect(() => {
    allSchedules()
}, []);

const userProject = []

const totalHours = []



schedules.map(elm => (elm.user._id === _id) && (elm.project._id === projectId._id) ? (elm.date == format(cloneDay, "cccc dd MMMM yyyy") ? totalHours.push(elm.hours) : null) : null)
let projects = schedules.map(elm => (elm.user._id === _id) && (elm.project._id === projectId._id) ? (elm.date == format(cloneDay, "cccc dd MMMM yyyy") ? <li key={elm._id}> <b>{elm.category}</b> - <b>{elm.hours} h</b> <p className='comment'>{elm.comment}</p></li> : null) : null)





    return (

        <>
        <Card>
            <Card.Body className='card-userInProject' onClick={openModal}>
                <div className="dia">
                <div className="row">
                    <div className="col">
                        <p className='titleproj'>{name} {lastname.slice(0, 3)}.</p>
                    </div>
                    <div className="col-md-auto">
                        <p className='hours'>{totalHours.reduce((a, b) => a + b, 0).toFixed(2).replace(/\.00$/, '')} h</p>
                    </div>

                </div>
                
                <div className="row">
                    <div className="col">
                    </div>
                </div>
                </div>
                {/* <Button style={{fontSize: ".8rem"}} onClick={openModal}>Detalles</Button> */}
            </Card.Body>
        </Card>
        
        
        
        


        <Modal show={modal.showModal} backdrop='static' onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Detalles</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>{name} {lastname}</div>
                <div>Coste por hora: {cost} €</div>
                <div>Hoy en este proyecto: <b> {totalHours.reduce((a, b) => a + b, 0).toFixed(2).replace(/\.00$/, '')} h. </b></div>
                <ul>{projects}</ul>
            </Modal.Body>
        </Modal>
        </>




    );
};

export default ProjectScheduleCard;