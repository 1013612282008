import React, { useState } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import AuthService from '../../../services/auth.service'
import UserService from '../../../services/user.service'
import './LoginPage.css'

const authService = new AuthService()
const userService = new UserService()



function LoginPage(props) {
    
    const [login, setLogin] = useState({
        email: "",
        password: ""
    });

    const { email, password } = login;

    const handleSubmit = (e) => {
        e.preventDefault();

        authService
            .login(email, password)
            .then(response => {
                props.storeUser(response.data)

                // RESETEA EL CONTADOR DEL TIEMPO DE SESION AL HACER LOGIN
                userService
                .checkSession()
                .then(res => {
                  const sessionStatus = res.data.sessionStatus
                  const session = sessionStatus.cookie.expires
                  const timestamp = Date.parse(session);
            
                  props.setSessionExpired(timestamp)
                })

            })
            .catch(err => console.log(err.response.data.message))
    }


    const handleInputChange = (e) => {
        const { name, value } = e.currentTarget

        setLogin({ ...login, [name]: value })
    }


    return (
            (
            <Container className='login titlespage' >
                <Row >

                        <Col md={{ span: 4, offset: 4 }}>
                            <h2>Acceder</h2>
                            <hr />
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control onChange={handleInputChange} value={email} name="email" type="text" placeholder="Email" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control onChange={handleInputChange} value={password} name="password" type="password" placeholder="Contraseña" />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Acceder
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>)
        )
}

export default LoginPage