import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import ProjectService from '../../../services/projects.service';

const projectService = new ProjectService();

const AddProject = (props) => {

    const [project, setProject] = useState({
        title: "",
        description: ""
    });

    const { title, description } = project;

    const handleSubmit = (e) => {
        e.preventDefault();

        
        projectService
            .addProject(project)
            .then(res => {
                props.closeModal()
                props.allProjects()
            })
            .catch(err => console.log(err))
    }

    const handleInputChange = (e) => {
        const { name, value } = e.currentTarget
        setProject({ ...project, [name]: value })
    }



    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' controlId='title'>
                <Form.Label>Titulo</Form.Label>
                <Form.Control onChange={handleInputChange} value={title} name="title" type="text" />
            </Form.Group>

            <Form.Group className='mb-3' controlId='description'>
                <Form.Label>Cliente</Form.Label>
                <Form.Control onChange={handleInputChange} value={description} name="description" type="text" />
            </Form.Group>

            <Button variant='primary' type='submit'>
                Crear
            </Button>
        </Form>
    );
};

export default AddProject;