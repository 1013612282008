import React, { useState } from 'react'
import { Row, Col, Button, Container } from 'react-bootstrap'
import UserCard from './UserCard'

function UserList({users, loggedUser, getAllUsers}) {

    const [todo, setTodo] = useState(true);
    const mostrarTodo = () => {
        setTodo(true)
        setshowAdmin(false)
        setShowUser(false)
        setShowExternal(false)
        setShowInactive(false)
    }

    
    const [showAdmin, setshowAdmin] = useState(false);
    const mostrarAdmin = () => {
        setTodo(false)
        setshowAdmin(true)
        setShowUser(false)
        setShowExternal(false)
        setShowInactive(false)

    }

    const [showUser, setShowUser] = useState(false);
    const mostrarUser = () => {
        setTodo(false)
        setshowAdmin(false)
        setShowUser(true)
        setShowExternal(false)
        setShowInactive(false)

    }

    const [showExternal, setShowExternal] = useState(false);
    const mostrarExternal = () => {
        setTodo(false)
        setshowAdmin(false)
        setShowUser(false)
        setShowExternal(true)
        setShowInactive(false)

    }

    const [showInactive, setShowInactive] = useState(false);
    const mostrarInactive = () => {
        setTodo(false)
        setshowAdmin(false)
        setShowUser(false)
        setShowExternal(false)
        setShowInactive(true)

    }
    
    return (
        <div className="padd-b-7">
                <Container>
                    <Button onClick={mostrarTodo}>Todos</Button>&nbsp;&nbsp;
                    <Button onClick={mostrarAdmin}>Admins</Button>&nbsp;&nbsp;
                    <Button onClick={mostrarUser}>Usuarios</Button>&nbsp;&nbsp;
                    <Button onClick={mostrarExternal}>Externos</Button>&nbsp;&nbsp;
                    <Button onClick={mostrarInactive}>Inactivos</Button>
                </Container>
                <br />
                <Row >
                    {todo === true ? users.sort(function(a, b) { if(a.name.toLowerCase() < b.name.toLowerCase()) return -1; if(a.name.toLowerCase() > b.name.toLowerCase()) return 1; return 0;}).map(elm => elm.role !== 'INACTIVE' ? <Col key={elm._id} style={{maxWidth: 'fit-content'}}><UserCard getAllUsers={getAllUsers} loggedUser={loggedUser} {...elm} /></Col> : null) : null }
                    {showAdmin === true ? users.sort(function(a, b) { if(a.name.toLowerCase() < b.name.toLowerCase()) return -1; if(a.name.toLowerCase() > b.name.toLowerCase()) return 1; return 0;}).map(elm => elm.role === 'ADMIN' ? <Col key={elm._id} style={{maxWidth: 'fit-content'}}><UserCard getAllUsers={getAllUsers} loggedUser={loggedUser} {...elm} /></Col> : null ) : null }
                    {showUser === true ? users.sort(function(a, b) { if(a.name.toLowerCase() < b.name.toLowerCase()) return -1; if(a.name.toLowerCase() > b.name.toLowerCase()) return 1; return 0;}).map(elm => elm.role === 'USER' ? <Col key={elm._id} style={{maxWidth: 'fit-content'}}><UserCard getAllUsers={getAllUsers} loggedUser={loggedUser} {...elm} /></Col> : null ) : null }
                    {showExternal === true ? users.sort(function(a, b) { if(a.name.toLowerCase() < b.name.toLowerCase()) return -1; if(a.name.toLowerCase() > b.name.toLowerCase()) return 1; return 0;}).map(elm => elm.role === 'USER_EXTERNAL' ? <Col key={elm._id} style={{maxWidth: 'fit-content'}}><UserCard getAllUsers={getAllUsers} loggedUser={loggedUser} {...elm} /></Col> : null ) : null }
                    {showInactive === true ? users.sort(function(a, b) { if(a.name.toLowerCase() < b.name.toLowerCase()) return -1; if(a.name.toLowerCase() > b.name.toLowerCase()) return 1; return 0;}).map(elm => elm.role === 'INACTIVE' ? <Col key={elm._id} style={{maxWidth: 'fit-content'}}><UserCard getAllUsers={getAllUsers} loggedUser={loggedUser} {...elm} /></Col> : null ) : null }
                </Row>
            {/* <Row>
                {users
                    .sort(function(a, b) { if(a.name.toLowerCase() < b.name.toLowerCase()) return -1; if(a.name.toLowerCase() > b.name.toLowerCase()) return 1; return 0;})
                    .map(elm => <Col key={elm._id} style={{maxWidth: 'fit-content'}}><UserCard loggedUser={loggedUser} {...elm} /></Col>)}
            </Row> */}
        </div>
    )
}

export default UserList