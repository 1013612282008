import React, {useState, useEffect} from 'react';

import UserService from '../../../services/user.service';
import { AllSchedules } from '../..';

const userService = new UserService();

const ActivityList = () => {

    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState({});

    const [showAllSchedules, setShowAllSchedules] = useState(false);


    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = () => {
        userService
            .getAllUsers()
            .then(response => {
                // Filter users who don't have the role "INACTIVE"
                const activeUsers = response.data.filter(user => user.role !== "INACTIVE");
                
                // Sort users first by accountsUser and then by currentProject
                activeUsers.sort((a, b) => {
                    if (a.currentProject && !b.currentProject) return -1;
                    if (!a.currentProject && b.currentProject) return 1;
                    if (!a.currentProject && !b.currentProject) {
                        if (a.accountsUser && !b.accountsUser) return 1;
                        if (!a.accountsUser && b.accountsUser) return -1;
                    }
                    return 0;
                });
                
                setUsers(activeUsers);
        
                // Create the structure of projects and users
                let projectData = {};
                activeUsers.forEach(user => {
                    if (user.currentProject) {
                        let projectName = user.currentProject.title;
                        if (!projectData[projectName]) {
                            projectData[projectName] = [];
                        }
                        projectData[projectName].push(user);
                    }
                });
    
                // Convert projectData into an array and sort it based on the number of users
                const sortedProjects = Object.entries(projectData).sort((a, b) => b[1].length - a[1].length);
                setProjects(Object.fromEntries(sortedProjects));
            })
            .catch(err => console.log(err));
    }

    const truncateTitle = (title, maxLength = 25) => {
        // Reemplazar todos los "_" por espacios
        title = title.replace(/_/g, ' ');
    
        if (title.length > maxLength) {
            return title.slice(0, maxLength) + '...';
        }
        return title;
    };
    
    
    

    return (
        <div className="equipo">
                    <div className="container mt-5 padd-b-20">
            <div className="row">
                <div className="col-md-6">
                <h4 className='padd-b-20 titlespage'>Actividad de usuarios</h4>
                    {/* Usuarios */}
                    <div className='row'>
                        {users.map((user, idx) => (
     
                            <div key={idx} className={`activity-user-card card ${!user.currentProject ? 'inactive' : ''} ${user.accountsUser ? 'accounts-user' : ''}`}>
                                <div className="card-body row align-items-center  "> 
                                <div className="col-auto padd-l-0">
                                <div
                                        className="rounded-circle  text-white d-inline-block "
                                        style={{ width: '50px', height: '50px', lineHeight: '50px'}}
                                    >
                                        {user.name.slice(0, 1).toUpperCase()}
                                        {user.lastname.slice(0, 1).toUpperCase()}
                                    </div>

                                </div>
                                    <div className='col'>
                                        <h5 className="card-title">{user.name}</h5>
                                        <p className="card-text">
                                            {user.currentProject ? truncateTitle(user.currentProject.title) : 'Sin tarea asignada'}
                                        </p>

                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
                <div className="col-md-6">
                <h4 className='padd-b-20 titlespage'>Carga de proyecto</h4>

                    {/* Proyectos */}
                    {Object.entries(projects).map(([project, usersInProject]) => (
                        <div key={project}>
                            <div className="card activity-project-card">
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div className='d-flex flex-column'>
                                        <h5 className="card-title">{project}</h5>
                                        <p style={{fontSize: '0.8rem'}} className='card-text'>
                                            {usersInProject.map((user, index) => (
                                                <span key={user._id}>
                                                    {user.name} {user.lastname}
                                                    {index !== usersInProject.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}

                                        </p>


                                    </div>

                                    <div
                                        className="rounded-circle  text-white d-inline-block "
                                        style={{ width: '50px', height: '50px', lineHeight: '50px'}}
                                    >
                                        {usersInProject.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

        <div className='text-center'>
            <button className='btn-users-calendar' onClick={() => setShowAllSchedules(true)}>Ver calendario de usuarios</button>
        </div>

        {showAllSchedules && <AllSchedules />}
                       



        </div>



    );
};

export default ActivityList;
