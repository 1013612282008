import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import ScheduleService from '../../../services/schedule.service';
import ProjectService from '../../../services/projects.service';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

const scheduleService = new ScheduleService();
const projectService = new ProjectService();

const AddSchedule = (props, { closeModal } ) => {

    const [calendar, setCalendar] = useState(new Date())
    const [projects, setProjects] = useState([])
    const [schedule, setSchedule] = useState({
        date: "",
        project: "",
        user: props.loggedUser._id,
        category: ""
    });

    const { date, hours, project, category } = schedule;


    const allProjects = () => {
        
        projectService
            .allProjects()
            .then(res => {
                const projects = res.data
                setProjects(projects)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        allProjects();
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();

        scheduleService
            .addSchedule(schedule)
            .then(res => {
                props.closeModal()
                props.allSchedules()
            })
            .catch(err => console.log(err))
    }


    const handleInputChange = (e) => {
        const { name, value } = e.currentTarget

        setSchedule({ ...schedule, [name]: value, date: calendar })
    }





    return (
        <Form onSubmit={handleSubmit}>
            <Calendar onChange={setCalendar} value={calendar}  />
            <Form.Group className='mb-3' controlId='date'>
                <Form.Label>Fecha</Form.Label>
                <Form.Control onChange={handleInputChange}  value={calendar} name="date" type="text"/>
            </Form.Group>


            <Form.Group className="mb-3" controlId="project">
                <Form.Label>Proyecto</Form.Label>
                 <Form.Select aria-label="Default select example" onChange={handleInputChange} name="project" type="text">
                    <option>Seleccionar</option>
                    {projects.map(elm => (
                        <option key={elm._id} value={elm._id}>{elm.title}</option>
                        ))}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="category">
                <Form.Label>Categoria</Form.Label>
                 <Form.Select aria-label="Default select example" onChange={handleInputChange} name="category" type="text">
                    <option>Seleccionar</option>
                    {category.map(elm => (
                        <option key={elm._id} value={elm._id}>{elm.name}</option>
                        ))}
                </Form.Select>
            </Form.Group>

            <Form.Group className='mb-3' controlId='hours'>
                <Form.Label>Horas</Form.Label>
                <Form.Control onChange={handleInputChange} value={hours} name="hours" type="number" maxlength="2" />
            </Form.Group>

            <Button variant='primary' type='submit'>
                Añadir
            </Button>
        </Form>
    );
};

export default AddSchedule;