import axios from 'axios'

class AuthService {
    constructor() {
        this.app = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/auth`,
            withCredentials: true
        })
    }
    signup = (name, lastname, password, email, role, projects, history) => this.app.post("/signup", { name, lastname, password, email, role, projects, history })
    login = (email, password) => this.app.post("/login", { email, password })
    logout = () => this.app.get("/logout")
    isloggedin = () => this.app.get("/isloggedin")
}

export default AuthService