import React, { useState, useEffect } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks
} from "date-fns";

import './Calendar.css'

import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { Container, Row, Col, Modal, Button, Form, Dropdown, FormControl } from "react-bootstrap";
import AllScheduleCard from "./AllScheduleCard";
import ScheduleService from "../../../services/schedule.service";
import AddSchedule from "./AddSchedule";
import ProjectService from '../../../services/projects.service';
import UserService from "../../../services/user.service";
import UserCard from "../UserList/UserCard";

const scheduleService = new ScheduleService()
const projectService = new ProjectService();
const userService = new UserService()




const Calendar = ( props ) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    
    // console.log("selected day", selectedDate);
    return (
      <div className="header row flex-middle  ">
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> */}
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
        
      );
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`tarjeta col cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "cccc dd MMMM yyyy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="number">{formattedDate}</span>
            <div className="longer-user-div">
              
            {users.map(elm => {
              let workArr = []


              let worked = schedules.map(elm2 => elm2.date == format(cloneDay, "cccc dd MMMM yyyy") && elm2.user._id == elm._id ? workArr.push(elm._id) : null  )
              return  workArr.includes(elm._id) ? <div key={elm._id}><AllScheduleCard cloneDay={cloneDay} loggedUser={props.loggedUser} {...elm} /> </div>  : null

            })}
            </div>

            
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row row-tarjetas" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <div className="header row flex-middle footer-fix ">
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            prev week
          </div>
        </div>
        <div className="week" >{currentWeek}</div>
        <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon">next week</div>
        </div>
      </div>
    );
  };



// MYSCHEDULE

const [schedules, setSchedules] = useState([]);

// todo
let filteredUserSchedules = []

const allSchedules = () => {

    scheduleService
        .allSchedules()
        .then(res => {
            const schedules = res.data
            setSchedules(schedules)

          })
        .catch(err => console.log(err))
}


const [modal, setModal] = useState({ showModal: false });

const closeModal = () => {
    setModal({
        showModal: false
    })
}

const openModal = () => {
    setModal({
        showModal: true
    })
}




// ADDPROJECT

const [projectUser, setProjectUser] = useState({

  user: []

})
const [projects, setProjects] = useState([])
const [schedule, setSchedule] = useState({
    date: "",
    project: "",
    hours: '',
    category: ""
});

const { date, hours, project, category } = schedule;


const allProjects = () => {
    
    projectService
        .allProjects()
        .then(res => {
            const projects = res.data
            let filteredProjects = projects.filter((project) => project.status === 'ABIERTO' )
      
            setProjects(filteredProjects)
        })
        .catch(err => console.log(err))
}


useEffect(() => {
    allSchedules()
    allProjects();
    getAllUsers()

}, []);



const projectId = schedule.project



const handleSubmit = (e) => {
    e.preventDefault();

    scheduleService
        .addSchedule(schedule)
        .then(res => {
            closeModal()
            allSchedules()
        })
        .catch(err => console.log(err));

      
    
    let proyecto = projects.filter((elm) => elm._id === project)
    let userInProject = proyecto[0].user.map(elm => {
     let arr = elm._id.includes(props.loggedUser._id) ? true : false
     return arr
    
    })

    console.log(userInProject.length)

    if(userInProject.length === 0){
      console.log('tonto')
      allProjects()
    }

    if (!userInProject.includes(true)) {
      projectService
      .addUser(projectId)
      .then(response => {
          const user = response.data
          setProjectUser({...projectUser, user: user})
      })
      .catch(err => console.log(err));
    } 
    





}

let proyecto = projects.filter((elm) => elm._id === project)


const handleInputChange = (e) => {
    const { name, value } = e.target



    // console.log(proyecto[0].user.includes(props.loggedUser._id))
    // console.log('Mi id es: ',props.loggedUser._id)
    // let userInProject = proyecto[0].user.includes(props.loggedUser._id)
    // console.log(e.target)
    // console.log(proyecto[0]?.category.map(elm => elm))

    setSchedule({ ...schedule, [name]: value, date: format(selectedDate, "cccc dd MMMM yyyy") })
}


const handleDelete = () => { 

  setSchedules([])
  allSchedules()
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));
  
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            controlId="project"
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  

    const options =  projects.map(elm => {return {value: elm._id, label: elm.title}})
    
  



  const handleProject = (e) => {

    const name = e.value



    setSchedule({ ...schedule, project: name })

}

    // mostrar todos los usuarios

    const [users, setUsers] = useState([]);


    const getAllUsers = () => {
      userService
          .getAllUsers()
          .then(response => {
              const users = response.data
              setUsers(users)
          })
          .catch(err => console.log(err))
  }

  return (
    <>



        <div className="calendar">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            {renderFooter()}
        </div>
        
    
    </>
  );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
